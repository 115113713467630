import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, where, getDocs, doc, getDoc, deleteDoc, updateDoc, orderBy, setDoc } from 'firebase/firestore';
import phoneCodes from './phoneCodes.json'; // Adjust the path as needed
import ClipLoader from "react-spinners/ClipLoader";



function ProfileForm() {
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
      };
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app); // Get the Firestore instance
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [cookies, setCookie, removeCookie] = useCookies(['userId']);
    const [userId, setUserId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [emergencyContact1Name, setemergencyContact1Name] = useState('');
    const [emergencyContact2Name, setemergencyContact2Name] = useState('');
    const [lastName, setLastName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userWhatsAppNo, setUserWhatsAppNo] = useState('');
    const [userCountryCode, setuserCountryCode] = useState('+91');
    const [userGender, setUserGender] = useState('');
    const [userDOB, setUserDOB] = useState('');
    const [userEmergencyContact1, setUserEmergencyContact1] = useState('');
    const [emergencyContact1CountryCode, setemergencyContact1CountryCode] = useState('+91');
    const [emergencyContact2CountryCode, setemergencyContact2CountryCode] = useState('+91');
    const [userEmergencyContact2, setUserEmergencyContact2] = useState('');
    const [userTableId, setUserTableId] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [errors, setErrors] = useState({});
    const [profileInfo, setProfileInfo] = useState({});
    const [emergencyInfo, setemergencyInfo] = useState({});
    const [IsEditButtonEnabled, setIsEditButtonEnabled] = useState(false);
    const [countries, setCountries] = useState([]);
    const [fullName, setFullName] = useState('');
    const [alreadyExistingNumberList, setAlreadyExistingNumberList] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        setLoading(true); // Set loader to true while fetching data
        // Convert the JSON data into an array of objects with country code and name
        const formattedCountries = Object.entries(phoneCodes).map(([code, number]) => ({
            code,
            number
        }));
        setCountries(formattedCountries);
    }, []);

    useEffect(() => {
        setLoading(true); // Set loader to true while fetching data
        const fetchWhatsAppNumbers = async () => {
            try {
                const q = query(collection(db, "UserTable"));
                const querySnapshot = await getDocs(q);
                const numbers = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    if (data.WhatsAppNo) {
                        numbers.push({
                            WhatsAppNo: data.WhatsAppNo,
                            documentId: doc.id
                        });
                    }
                });
                setAlreadyExistingNumberList(numbers);
            } catch (error) {
                console.error("Error fetching WhatsApp numbers:", error);
            }
        };
        fetchWhatsAppNumbers();
    }, []); // Empty dependency array means this effect runs once on component mount



    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const userIdFromLocalStorage = localStorage.getItem('userId');
                const userIdFromCookies = cookies.userId;
                const userId = userIdFromLocalStorage || userIdFromCookies;

                if (userId) {
                    setUserId(userId);
                    const docRef = doc(db, "UserTable", userId);
                    const docSnap = await getDoc(docRef);
                    const dataDoc = docSnap.data();
                    
                    if (docSnap.exists()) {
                        setProfileInfo(dataDoc);
                        setFullName(dataDoc.FullName || '');
                        setFirstName(dataDoc.FirstName || '');
                        setLastName(dataDoc.LastName || '');
                        setUserEmail(dataDoc.Email || '');
                        setUserWhatsAppNo(dataDoc.WhatsAppNo || '');
                        setuserCountryCode(dataDoc.UserCountryCode || '+91');
                        setUserGender(dataDoc.Gender || '');
                        setUserDOB(dataDoc.DateOfBirth || '');
                        setUserEmergencyContact1(dataDoc.EmergencyContact1 || '');
                        setemergencyContact1Name(dataDoc.EmergencyContact1Name || '');
                        setemergencyContact1CountryCode(dataDoc.EmergencyContact1CountryCode || '+91');
                        setemergencyContact2CountryCode(dataDoc.EmergencyContact2CountryCode || '+91');
                        setUserEmergencyContact2(dataDoc.EmergencyContact2 || '');
                        setemergencyContact2Name(dataDoc.EmergencyContact2Name || '');
                        setIsEditButtonEnabled(dataDoc.isProfileComplete || false);
                    }
                } else {
                    navigate('/login');
                }
            } catch (error) {
                console.error("Error fetching documents:", error);
            } finally {
                setIsDataLoaded(true);
                setLoading(false);
            }
        };

        fetchData();
    }, [cookies, navigate]);

    const handleLogout = () => {
        // Remove userId from cookies
        removeCookie('userId');
        // Remove userId from localStorage
        localStorage.removeItem('userId');
        // Redirect to the login page or home page after logout
        window.location.href = '/login'; // Adjust the URL as needed
    };


    const validateForm = () => {
        let formErrors = {};
        const validatePhoneNumber = (number) => number.length === 10 && !isNaN(number);
        // Existing validation logic
        if (!fullName.trim()) formErrors.fullName = "Full Name is required.";
        if (!emergencyContact1Name.trim()) formErrors.emergencyContact1Name = "Emergency Contact 1 Name is required.";
        if (!emergencyContact2Name.trim()) formErrors.emergencyContact2Name = "Emergency Contact 2 Name is required.";
        if (!userGender.trim()) formErrors.userGender = "Gender is required.";
        if (!userEmail.trim()) {
            formErrors.userEmail = "Email is required.";
        } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(userEmail)) {
            formErrors.userEmail = "Invalid email format.";
        }
        if (!userWhatsAppNo.trim()) {
            formErrors.userWhatsAppNo = "WhatsApp number is required.";
        } else if (!validatePhoneNumber(userWhatsAppNo)) {
            formErrors.userWhatsAppNo = "WhatsApp number must be 10 digits.";
        }
        if (!userEmergencyContact1.trim()) {
            formErrors.userEmergencyContact1 = "Emergency Contact Number is required.";
        } else if (!validatePhoneNumber(userEmergencyContact1)) {
            formErrors.userEmergencyContact1 = "Contact Number must be 10 digits.";
        }
        if (!userEmergencyContact2.trim()) {
            formErrors.userEmergencyContact2 = "Emergency Contact Number is required.";
        } else if (!validatePhoneNumber(userEmergencyContact2)) {
            formErrors.userEmergencyContact2 = "Contact Number must be 10 digits.";
        }

        // New validation logic
        const contact1IsValid = validatePhoneNumber(userEmergencyContact1);
        const contact2IsValid = validatePhoneNumber(userEmergencyContact2);
        const whatsAppNoIsValid = validatePhoneNumber(userWhatsAppNo);

        if (whatsAppNoIsValid && contact1IsValid && contact2IsValid) {
            if (userWhatsAppNo === userEmergencyContact1) {
                formErrors.userEmergencyContact1 = "Emergency Contact 1 must be different from WhatsApp number.";
            }
            if (userWhatsAppNo === userEmergencyContact2) {
                formErrors.userEmergencyContact2 = "Emergency Contact 2 must be different from WhatsApp number.";
            }
            if (userEmergencyContact1 === userEmergencyContact2) {
                formErrors.userEmergencyContact2 = "Emergency Contact 2 must be different from Emergency Contact 1.";
            }
        }

        // Ensure emergency contact names are different from each other
        if (emergencyContact1Name.trim() && emergencyContact2Name.trim() && emergencyContact1Name === emergencyContact2Name) {
            formErrors.emergencyContact2Name = "Emergency Contact 2 Name must be different from Emergency Contact 1 Name.";
        }

        // Validate country code
        if (!userCountryCode) {
            formErrors.userCountryCode = "Country code is required.";
        }
        if (!emergencyContact1CountryCode) {
            formErrors.emergencyContact1CountryCode = "Country code of Emergency Contact 1 is required.";
        }
        if (!emergencyContact2CountryCode) {
            formErrors.emergencyContact2CountryCode = "Country code of Emergency Contact 2 is required.";
        }

        // Check if WhatsApp number is already in the existing list
        const existingEntry = alreadyExistingNumberList.find(
            entry => entry.WhatsAppNo === userWhatsAppNo
        );

        if (existingEntry && existingEntry.documentId !== userId) {
            formErrors.userWhatsAppNo = "WhatsApp number is already in use.";
        }

        // Validate Date of Birth
        const today = new Date();
        const dob = new Date(userDOB);
        if (!userDOB.trim()) {
            formErrors.userDOB = "Date of Birth is required.";
        } else if (dob >= today) {
            formErrors.userDOB = "Date of Birth must be strictly before today.";
        }

        setErrors(formErrors);
        setIsButtonDisabled(Object.keys(formErrors).length > 0);

        return formErrors;
    };

    const handleSave = async (event) => {
        try {
            event.preventDefault(); // Prevent default form submission behavior
            const errors = validateForm();
            if (Object.keys(errors).length > 0) {
                return;
            } else {
                setLoading(true); // Set loader to true while fetching data
                await setDoc(doc(db, "UserTable", userId), {
                    "WhatsAppNo": userWhatsAppNo,
                    "UserCountryCode": userCountryCode,
                    "FullName": fullName,
                    "Email": userEmail,
                    "DateOfBirth": userDOB,
                    "Gender": userGender,
                    "CreatedOn": new Date(), // Use `new Date()` to get the current timestamp
                    "AccountStatus": "active",
                    "isProfileComplete": true,
                    "EmergencyContact1Name": emergencyContact1Name,
                    "EmergencyContact2Name": emergencyContact2Name,
                    "EmergencyContact1CountryCode": emergencyContact1CountryCode,
                    "EmergencyContact2CountryCode": emergencyContact2CountryCode,
                    "EmergencyContact1": userEmergencyContact1,
                    "EmergencyContact2": userEmergencyContact2
                });
                navigate('/alerts');
            }

        } catch (error) {
            console.error("Error adding document: ", error);
        } finally {
            setLoading(false); // Stop loading after data is fetched
        }
    };
    const onBackCreateAlert = () => {
        navigate('../alerts'); // Replace '/your-target-page' with the route you want to navigate to
    };

    return (
        <div className={loading ? 'overflow-hidden' : ''} style={{ position: 'relative', height: '100vh', backgroundColor: '#f0f1f3', overflowY: loading ? 'hidden' : 'auto' }}>
             <div
                className="container-fluid d-flex flex-column"
                style={{
                    maxWidth: '480px',
                    backgroundColor: '#f0f1f3',
                    height: '100%'
                }}
            >
                <div className="col d-flex justify-content-center align-items-center">
                    <h1
                        className="mb-0"
                        style={{
                            fontSize: '2rem',
                            fontWeight: 'bolder',
                            fontStyle: 'italic',
                            textAlign: 'center',
                            fontFamily: 'Arial, sans-serif' // Change to your preferred font
                        }}
                    >
                        alerts
                    </h1>
                </div>
                <header className="row py-3">
                    <div className="col">
                        <a className="text-blue text-decoration-none" onClick={onBackCreateAlert}>← Back</a>
                    </div>
                    <div className="col-auto">
                        <a className="text-white text-decoration-none" onClick={handleLogout}><span style={{ color: 'red' }}>Log Out</span></a>
                    </div>
                </header>
                <main className="row flex-grow-1 mb-2">
                    <div className="col p-3">
                        {!IsEditButtonEnabled && (
                            <div className="alert alert-warning" role="alert" style={{ backgroundColor: '#fffbe6', border: '1px solid #ffe58f', borderRadius: '4px' }}>
                                <strong>Action Required</strong>
                                <p className="mb-0">Please complete your profile and add 2 emergency contacts before you can create an alert.</p>
                            </div>
                        )}
                        <div className="bg-white p-3 rounded">
                            <h2 className="h5 mb-3">YOU</h2>
                            <div className="mb-3">
                                <label htmlFor="fullName" className="form-label text-uppercase small fw-bold text-secondary">FULL NAME</label>
                                <input
                                    type="text"
                                    onChange={(e) => {
                                        setFullName(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, fullName: '' }));
                                    }}
                                    className={`form-control ${errors.fullName ? 'is-invalid' : ''}`}
                                    id="firstName"
                                    value={fullName}
                                    maxLength={100} // Set maximum characters to 100
                                    min={1}
                                    pattern="^[a-zA-Z0-9]{2,100}$" // Regex for alphanumeric string with min 2 and max 100 characters
                                />
                                {errors.fullName && <div className="invalid-feedback">{errors.fullName}</div>}
                            </div>

                            <div className="mb-3">
                                <label htmlFor="email" className="form-label text-uppercase small fw-bold text-secondary">EMAIL ID</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    disabled
                                    value={userEmail}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="whatsapp" className="form-label text-uppercase small fw-bold text-secondary">WHATSAPP NUMBER</label>
                                <div className="d-flex align-items-center">
                                    <select
                                        id="country"
                                        name="country"
                                        className={`form-select form-select-sm me-2 ${errors.userCountryCode ? 'is-invalid' : ''}`}
                                        onChange={(e) => {
                                            setuserCountryCode(e.target.value);
                                            setErrors((prevErrors) => ({ ...prevErrors, userCountryCode: '' }));
                                        }}
                                        value={userCountryCode}
                                        style={{ flexBasis: '35%', height: '36px' }} // Adjusted size to be wider

                                    >
                                        {/* Corrected placeholder option */}
                                        <option value="">Select country code</option>
                                        {countries.map((country) => (
                                            <option key={country.code} value={country.number}>
                                                {country.code} {country.number}
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                        type="tel"
                                        onChange={(e) => {
                                            setUserWhatsAppNo(e.target.value);
                                            setErrors((prevErrors) => ({ ...prevErrors, userWhatsAppNo: '' }));
                                        }}
                                        className={`form-control ${errors.userWhatsAppNo ? 'is-invalid' : ''}`}
                                        id="whatsapp"
                                        value={userWhatsAppNo}
                                        style={{ flexBasis: '65%' }} // Adjusted size to be wider

                                    />

                                </div>
                                {/* Validation messages */}
                                <div className="mt-2">
                                    {errors.userCountryCode && <div className="invalid-feedback d-block">{errors.userCountryCode}</div>}
                                </div>
                                <div className="mt-2">
                                    {errors.userWhatsAppNo && <div className="invalid-feedback d-block">{errors.userWhatsAppNo}</div>}
                                </div>
                            </div>


                            <div className="mb-3">
                                <label htmlFor="dob" className="form-label text-uppercase small fw-bold text-secondary">DATE OF BIRTH</label>
                                <input
                                    type="date"
                                    onChange={(e) => {
                                        setUserDOB(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, userDOB: '' }));
                                    }}
                                    className={`form-control ${errors.userDOB ? 'is-invalid' : ''}`}
                                    id="dob"
                                    value={userDOB}
                                />
                                {errors.userDOB && <div className="invalid-feedback">{errors.userDOB}</div>}
                            </div>

                            <div className="mb-3">
                                <label htmlFor="gender" className="form-label text-uppercase small fw-bold text-secondary">GENDER</label>
                                <select
                                    onChange={(e) => {
                                        setUserGender(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, userGender: '' }));
                                    }}
                                    className={`form-select ${errors.userGender ? 'is-invalid' : ''}`}
                                    id="gender"
                                    value={userGender}
                                >
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                                {errors.userGender && <div className="invalid-feedback">{errors.userGender}</div>}
                            </div>

                        </div>
                    </div>
                </main>
                {/* Emergency Contact #1 */}
                <div className="bg-white p-3 rounded mb-4">
                    <div className="card-body">
                        <h2 className="h5 mb-3">Emergency Contact #1</h2>
                        <div className="mb-3">
                            <label htmlFor="emergencyContact1Name" className="form-label text-uppercase small fw-bold text-secondary">NAME</label>
                            <input
                                type="text"
                                onChange={(e) => {
                                    setemergencyContact1Name(e.target.value);
                                    setErrors(prevErrors => ({ ...prevErrors, emergencyContact1Name: '' }));
                                }}
                                className={`form-control ${errors.emergencyContact1Name ? 'is-invalid' : ''}`}
                                id="emergencyContact1Name"
                                value={emergencyContact1Name}
                                maxLength={100} // Set maximum characters to 100
                                min={1}
                                pattern="^[a-zA-Z0-9]{2,100}$" // Regex for alphanumeric string with min 2 and max 100 characters
                            />
                            {errors.emergencyContact1Name && <div className="invalid-feedback">{errors.emergencyContact1Name}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="ec1Whatsapp" className="form-label text-uppercase small fw-bold text-secondary">WHATSAPP NUMBER</label>
                            <div className="d-flex">
                                <select
                                    id="emergencyCountry1"
                                    name="emergencyCountry1"
                                    className={`form-select form-select-sm me-2 ${errors.emergencyContact1CountryCode ? 'is-invalid' : ''}`}
                                    onChange={(e) => {
                                        setemergencyContact1CountryCode(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, emergencyContact1CountryCode: '' }));
                                    }}
                                    value={emergencyContact1CountryCode}
                                    style={{ flexBasis: '35%' }} // Adjusted size to be smaller
                                >
                                    <option value="">Select country code</option>
                                    {countries.map((country) => (
                                        <option key={country.code} value={country.number}>
                                            {country.code} {country.number}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    type="tel"
                                    onChange={(e) => {
                                        setUserEmergencyContact1(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, userEmergencyContact1: '' }));
                                    }}
                                    className={`form-control ${errors.userEmergencyContact1 ? 'is-invalid' : ''}`}
                                    id="ec1Whatsapp"
                                    value={userEmergencyContact1}
                                    style={{ flexBasis: '65%' }} // Adjusted size to be wider

                                />
                            </div>
                            {errors.emergencyContact1CountryCode && <div className="invalid-feedback d-block">{errors.emergencyContact1CountryCode}</div>}
                            {errors.userEmergencyContact1 && <div className="invalid-feedback d-block">{errors.userEmergencyContact1}</div>}
                        </div>
                    </div>
                </div>
                <div className="bg-white p-3 rounded mb-4">
                    <div className="card-body">
                        <h2 className="h5 mb-3">Emergency Contact #2</h2>
                        <div className="mb-3">
                            <label htmlFor="emergencyContact2Name" className="form-label text-uppercase small fw-bold text-secondary">NAME</label>
                            <input
                                type="text"
                                onChange={(e) => {
                                    setemergencyContact2Name(e.target.value);
                                    setErrors(prevErrors => ({ ...prevErrors, emergencyContact2Name: '' }));
                                }}
                                className={`form-control ${errors.emergencyContact2Name ? 'is-invalid' : ''}`}
                                id="emergencyContact1Name"
                                value={emergencyContact2Name}
                                style={{ flexBasis: '75%' }} // Adjusted size to be wider
                                maxLength={100} // Set maximum characters to 100
                                min={1}
                                pattern="^[a-zA-Z0-9]{2,100}$" // Regex for alphanumeric string with min 2 and max 100 characters

                            />
                            {errors.emergencyContact2Name && <div className="invalid-feedback">{errors.emergencyContact2Name}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="ec1Whatsapp" className="form-label text-uppercase small fw-bold text-secondary">WHATSAPP NUMBER</label>
                            <div className="d-flex">
                                <select
                                    id="emergencyCountry2"
                                    name="emergencyCountry2"
                                    className={`form-select form-select-sm me-2 ${errors.emergencyContact2CountryCode ? 'is-invalid' : ''}`}
                                    onChange={(e) => {
                                        setemergencyContact2CountryCode(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, emergencyContact2CountryCode: '' }));
                                    }}
                                    value={emergencyContact2CountryCode}
                                    style={{ flexBasis: '35%' }} // Adjusted size to be smaller
                                >
                                    <option value="">Select country code</option>
                                    {countries.map((country) => (
                                        <option key={country.code} value={country.number}>
                                            {country.code} {country.number}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    type="tel"
                                    onChange={(e) => {
                                        setUserEmergencyContact2(e.target.value);
                                        setErrors(prevErrors => ({ ...prevErrors, userEmergencyContact2: '' }));
                                    }}
                                    className={`form-control ${errors.userEmergencyContact2 ? 'is-invalid' : ''}`}
                                    id="ec2Whatsapp"
                                    value={userEmergencyContact2}
                                    style={{ flexBasis: '65%' }} // Adjusted size to be wider
                                />
                            </div>

                            {errors.emergencyContact2CountryCode && <div className="invalid-feedback d-block">{errors.emergencyContact2CountryCode}</div>}
                            {errors.userEmergencyContact2 && <div className="invalid-feedback d-block">{errors.userEmergencyContact2}</div>}
                        </div>
                    </div>
                </div>
                <button type="button" className="btn btn-primary w-100 mb-3" onClick={handleSave} style={{ backgroundColor: '#111827' }}>
                    {!IsEditButtonEnabled ? 'SAVE' : 'Update Profile'}
                </button>
            </div>

            {loading && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        backdropFilter: 'blur(8px)',
                        zIndex: 9999,
                    }}
                >
                    <ClipLoader color={"#111827"} loading={loading} size={100} borderWidth={800} />
                </div>
            )}
        </div>
    );
}

export default ProfileForm;
