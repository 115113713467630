import React, { useState, useEffect } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFirestore, collection, addDoc, setDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import defaultlogo from './defaultlogo.jpg'
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

function LoginSignUp() {
  const navigate = useNavigate();
  const [isSignUp, setIsSignUp] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState('');

 const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const provider = new GoogleAuthProvider();
  const [cookies, setCookie] = useCookies(['userId']);
  const db = getFirestore(app);
  const [userId, setUserId] = useState('');

  useEffect(() => {
    const userIdCookie = cookies.userId;
    const localUserId = localStorage.getItem('userId');
    if (userIdCookie) {
      setUserId(userIdCookie);
      navigate('/alerts'); // Navigate to "/alerts" if userId exists in cookies
    } else if (localUserId) {
      setUserId(localUserId);
      setCookie('userId', localUserId, { path: '/' });
      navigate('/alerts'); // Navigate to "/alerts" if userId exists in localStorage
    }
    setLoading(false);
  }, [cookies, setCookie, navigate]);
  const capitalizeWords = (str) => {
    return str
      .split(' ') // Split the string by spaces into an array of words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
      .join(' '); // Join the words back into a single string
  };

  const signInWithGoogle = async () => {
    try {
      setLoading(true);
      setLoading(false);
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const userId = user.uid;
      const userName = user.displayName;
      const email = user.email;
      const userName2 = capitalizeWords(userName);
      if (userId) {
        localStorage.setItem('userId', userId);
        document.cookie = `userId=${userId}; expires=your_expiry_time_in_gmt; path=/`;
        const docRef = doc(db, "UserTable", userId);
        const docSnap = await getDoc(docRef);
        const dataDoc = docSnap.data();
        if (!docSnap.exists()) {
          await setDoc(doc(db, "UserTable", userId), {
            FullName: userName2,
            Email: email,
            isProfileComplete: false
          });
          debugger
        }
        else if (dataDoc.isProfileComplete == null || dataDoc.isProfileComplete == undefined) {
          navigate('../ProfileForm')
        }
        else {
          navigate('../alerts');
        }
      }
    } catch (error) {
      const errorMessage = error.message;
      alert(errorMessage);
    }
  };

  const toggleForm = () => {
    setIsSignUp(!isSignUp);
  };

  const handleLogin = async () => {
    if (!username || !password) {
      alert("Fill the required Fields!");
      return;
    }

    if (isSignUp && !confirmPassword) {
      alert("Confirm Your Password");
      return;
    }

    if (isSignUp && password !== confirmPassword) {
      alert("Password and Confirm Password do not match");
      return;
    }

    const authFunction = isSignUp ? createUserWithEmailAndPassword : signInWithEmailAndPassword;

    authFunction(auth, username, password)
      .then((userCredential) => {
        const user = userCredential.user;
        const userId = user.uid;
        if (userId) {
          localStorage.setItem('userId', userId);
          document.cookie = `userId=${userId}; expires=your_expiry_time_in_gmt; path=/`;
          navigate('../alerts');
        }
      })
      .catch((error) => {
        const errorMessage = error.message;
        alert(errorMessage);
      });
  };

  return (
    <div className={loading ? 'overflow-hidden' : ''} style={{ position: 'relative', height: '100vh', backgroundColor: '#f0f1f3' }}>
      <div className="container-fluid d-flex flex-column" style={{ maxWidth: '480px', height: '100%', overflowY: loading ? 'hidden' : 'auto' }}>
        <main className="row flex-grow-1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className="col d-flex flex-column justify-content-center align-items-center">
            <h1
              className="mb-0"
              style={{
                fontSize: '2rem',
                fontWeight: 'bolder',
                fontStyle: 'italic',
                textAlign: 'center',
                fontFamily: 'Arial, sans-serif' // Change to your preferred font
              }}
            >
              alerts
            </h1>
            <button
              className="btn btn-lg mb-3"
              onClick={signInWithGoogle}
              style={{
                backgroundColor: '#FFF',
                borderColor: '#black',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '80%',
                padding: '10px',
                marginTop: '20px', // Add margin-top to space out the button from the image
                border: '1px solid black' // Ensure the border color is set
              }}
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg" // Replace this with your actual logo URL
                alt="Google Logo"
                style={{ marginRight: '10px', width: '24px', height: '24px' }} // Adjust width and height as needed
              />
              Continue with Google
            </button>

            <div className="row text-center mt-1">
              <div className="col">
                <div className="mt-3">
                  <a href="https://docs.google.com/document/d/1--85XVU2S2VdyUKD_FS9QVxJAcVJyWvWcLNy_bWp8MU/pub" target="_blank" className="text-muted me-3">Terms and Conditions</a>
                  <a href="https://docs.google.com/document/d/1WH0-7Bj-9VlO-3raIKzB1L3m3aG0kxbGJmyhmCBCv3Q/pub" target="_blank" className="text-muted">Privacy Policy</a>
                </div>
              </div>
            </div>

          </div>
        </main>
      </div>
      {
        loading && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              backdropFilter: 'blur(8px)',
              zIndex: 9999,
            }}
          >
            <ClipLoader color={"#111827"} loading={loading} size={100} borderWidth={800} />
          </div>
        )
      }
    </div >
  );
}

export default LoginSignUp;