import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Privacy Policy</h1>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Link to="/login" style={{ color: '#007bff', textDecoration: 'none', fontSize: '16px' }}>
        ← Back
        </Link>
      </div>
      <p>
        This Privacy Policy outlines how we collect, use, and protect your information.
      </p>
      <ul style={{ paddingLeft: '20px' }}>
        <li>We use Google Auth from Firebase for authentication.</li>
        <li>We store user data securely in Firebase.</li>
        <li>We utilize Firebase Auth for user management.</li>
        <li>We do not share your personal information with third parties without your consent.</li>
        <li>Users have the right to access and delete their data at any time.</li>
      </ul>
      <p>
        For any questions regarding this policy, please contact us.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
