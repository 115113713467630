import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import ClipLoader from "react-spinners/ClipLoader";
const TripDetails = () => {
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
      };
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const { Id } = useParams();
    const [alertData, setAlertData] = useState({});
    const [userData, setUserData] = useState({});

    const formatDateOfBirth = (dob) => {
        // Convert Firestore Timestamp to Date if necessary
        let birthDate;
    
        if (dob && dob.toDate) {
            birthDate = dob.toDate();
        } else if (typeof dob === 'string') {
            birthDate = new Date(dob);
        } else if (dob instanceof Date) {
            birthDate = dob;
        } else {
            return 'Invalid date';
        }
    
        // Check if birthDate is a valid Date object
        if (isNaN(birthDate.getTime())) {
            return 'Invalid date';
        }
    
        const today = new Date();
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
    
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            return `${age - 1}`;
        }
    
        return `${age}`;
    };
    

    const formatDateTime = (inputDate) => {
        // Convert Firestore Timestamp to Date if necessary
        if (inputDate && inputDate.toDate) {
            inputDate = inputDate.toDate();
        }
        const date = new Date(inputDate);

        // Get the day, month, and year
        const day = date.getDate();
        const year = date.getFullYear();

        // Convert the month to a 3-letter uppercase abbreviation
        const month = date.toLocaleString('en-US', { month: 'short' }).toUpperCase();

        // Get the time in 12-hour format with AM/PM
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHour = hours % 12 || 12; // Convert to 12-hour format

        // Combine the date and time into a single string
        const formattedDateTime = `${day} ${month} ${year} : ${formattedHour}:${minutes} ${ampm}`;
        return formattedDateTime;
    };

    useEffect(() => {
        setLoading(true); // Set loader to true while fetching data
        if (!Id) {
            navigate("./alerts");
            return;
        }

        const fetchAlertData = async () => {
            try {
                const docRef = doc(db, "AlertTable", Id);
                const docSnap = await getDoc(docRef);
                const data2 = docSnap.data();
                const userId = data2.UserId;
                const docRef2 = doc(db, "UserTable", userId);
                const docSnap2 = await getDoc(docRef2);
                const data3 = docSnap2.data();
                setAlertData(data2);
                setUserData(data3);
                console.log(data3.DateOfBirth);
            } catch (error) {
                console.error("Error fetching alert data:", error);
            } finally {
                setLoading(false); // Stop loading after data is fetched
            }
        };
        fetchAlertData();
    }, [Id, navigate]);

    const copyToClipboard = () => {
        const text = `https://alerts.creedaz.in/share/${Id}`;
        const tempTextArea = document.createElement('textarea');
        tempTextArea.value = text;
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        document.execCommand('copy');
        document.body.removeChild(tempTextArea);
        alert("Copied To Clipboard!");
        // Optionally, you can provide user feedback or notifications about the successful copy
    };

    return (
        <>
            {loading && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        backdropFilter: 'blur(8px)',
                        zIndex: 9999,
                    }}
                >
                    <ClipLoader color={"#111827"} loading={loading} size={100} borderWidth={800} />
                </div>
            )}
            <div className={`container-fluid d-flex flex-column min-vh-100 ${loading ? 'overflow-hidden' : ''}`} style={{ maxWidth: '480px', backgroundColor: '#f0f0f0',overflowX:'hidden' }}>
                <header className="row text-black py-3">
                    <div className="col d-flex justify-content-center align-items-center">
                        <h1
                            className="mb-0"
                            style={{
                                fontSize: '2rem',
                                fontWeight: 'bolder',
                                fontStyle: 'italic',
                                textAlign: 'center',
                                fontFamily: 'Arial, sans-serif' // Change to your preferred font
                            }}
                        >
                            alerts
                        </h1>
                    </div>
                </header>
                <div className='card'>
                    <div className='card-body'>
                        <main className="row flex-grow-1 mb-2">
                            <div className="col">
                                <div className="d-flex justify-content-between align-items-center mb-3" style={{ height: '60px' }}>
                                    <div>
                                        <h2 className="h4 mb-0 text-nowrap" style={{ fontSize: '1.5rem' }}>Trip Details</h2>
                                    </div>
                                    <div>
                                        <button className="btn btn-link p-0 ml-auto text-decoration-none" aria-label="Share" style={{ fontSize: '1.0rem' }} onClick={copyToClipboard}>
                                            Copy Link &nbsp;
                                            <FontAwesomeIcon icon={faLink} onClick={copyToClipboard} />
                                        </button>
                                    </div>
                                </div>

                                {alertData.IsTripCompleted && (
                                    <div className="alert alert-success pb-0 text-left">
                                        <p><strong>Update:</strong><br /> The missing person has marked themselves as <strong>"I'm Back & Safe"</strong> for this trip on our platform at <strong>{alertData.BackAndSafeTime ? formatDateTime(alertData.BackAndSafeTime) : 'N/A'}</strong><br />
                                            We recommend to directly contact them and verify.
                                        </p>
                                    </div>
                                )}
                                <div className="mb-3">
                                    <h5 className="form-label text-uppercase small fw-bold text-secondary">Name</h5>
                                    <p>{userData.FullName} ({formatDateOfBirth(userData.DateOfBirth)}, {userData.Gender})</p>
                                </div>
                                <div className="mb-3">
                                    <h5 className="form-label text-uppercase small fw-bold text-secondary">Group Size</h5>
                                    <p>{alertData.GroupSize || ''}</p>
                                </div>
                                <div className="mb-3">
                                    <h5 className="form-label text-uppercase small fw-bold text-secondary">Trip Name</h5>
                                    <p>{alertData.TripName}</p>
                                </div>
                                <div className="mb-3">
                                    <h5 className="form-label text-uppercase small fw-bold text-secondary">Location</h5>
                                    <p>{alertData.NearestTown}, {alertData.State}</p>
                                </div>
                                <div className="mb-3">
                                    <h5 className="form-label text-uppercase small fw-bold text-secondary">Expected Return (IST)</h5>
                                    <p>{alertData.ReturnTimestamp ? formatDateTime(alertData.ReturnTimestamp) : 'N/A'}</p>
                                </div>
                                <div className="mb-3">
                                    <h5 className="form-label text-uppercase small fw-bold text-secondary">Activity</h5>
                                    <p>{alertData.Activity}</p>
                                </div>
                                <div className="mb-3">
                                    <h5 className="form-label text-uppercase small fw-bold text-secondary">Route Details</h5>
                                    <p>{alertData.RouteDetails}</p>
                                </div>
                                <div className="mb-3">
                                    <h5 className="form-label text-uppercase small fw-bold text-secondary">Created On</h5>
                                    <p>{alertData.CreatedOn ? formatDateTime(alertData.CreatedOn) : 'N/A'}</p>
                                </div>
                            </div>
                        </main>

                    </div>
                </div>
                <footer className="row bg-dark text-white py-3 mt-3 rounded">
                    <div className="col">
                        <div className="p-3">
                            <h5>What's this?</h5>
                            <p>
                                This is an online platform where hikers can enter trip information before heading outdoors. If they don't return on time, an alert is automatically created and shared with emergency contacts. The provided information may assist in a search and rescue operation.
                            </p>
                        </div>
                    </div>
                </footer>

            </div>
        </>

    );
};

export default TripDetails;
