import React from 'react';
import { Link } from 'react-router-dom';


const TermsOfService = () => {
  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Terms of Service</h1>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Link to="/login" style={{ color: '#007bff', textDecoration: 'none', fontSize: '16px' }}>
        ← Back
        </Link>
      </div>
      <p><strong>Name:</strong> Alerts</p>
      <h2>Terms of Use</h2>
      <ul style={{ paddingLeft: '20px' }}>
        <li>
          <strong>Service Overview:</strong> Alerts is a web-based system designed to automatically notify a user-selected person or group of people when the user is overdue returning from a scheduled hike or other event, indicating that the user might be lost, injured, or in some other form of peril.
        </li>
        <li>
          <strong>Notification Limits:</strong> Alerts limits all notifications to email and WhatsApp messaging only. Alerts does not use any form of voice communication methods to distribute or respond to notifications. Alerts IS NOT a professional emergency response service or search and rescue service, nor is Alerts affiliated with any such organizations.
        </li>
        <li>
          <strong>Responsibility for Authorities:</strong> Alerts does not directly notify or contact any official search and rescue authorities. It is the responsibility of the user’s selected emergency contact person(s) to notify any and all appropriate authorities.
        </li>
        <li>
          <strong>Liability:</strong> Alerts is not liable for any special, indirect, or consequential damages arising from the use of the site.
        </li>
        <li>
          <strong>Submissions:</strong> By submitting information, users grant Alerts permission to use it in connection with its services and to inform users about new services, events, or products. No compensation is provided for submissions.
        </li>
        <li>
          <strong>Disclaimer:</strong> Alerts is provided "as is" without warranties of any kind. Alerts is not liable for any interruptions or inaccuracies.
        </li>
        <li>
          <strong>Indemnification:</strong> Users agree to indemnify Alerts against any claims arising from their use of the site.
        </li>
      </ul>
    </div>
  );
};

export default TermsOfService;
